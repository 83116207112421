export default [
  {
    key: "imageId",
    initKey: "image",
    label: "field.image",
    type: "async-single-image",
    path: "directory",
    rules: "required",
    pw: 390,
    ph: 300,
    fullWidth: false,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    type: "text"
  },
  {
    cols: 6,
    skip: true,
    hide: true,
  },
  {
    key: "isEnable",
    label: "field.active",
    type: "checkbox",
    cols: "auto",
  },
]
